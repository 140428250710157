






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard, GiftCardRate, Caretaker } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'AddRatePopover'
})
export default class extends Vue {
  @Prop({ required: true }) private caretaker!: Caretaker

  private search = ''
  private giftCardRate = new GiftCardRate({ caretaker: this.caretaker })
  private createRate = false

  private rules = {
    rate: [
      { required: true, message: 'Le taux de remise est requis', trigger: 'blur' }
    ],
    giftCard: [
      { required: true, message: 'L\'enseigne est requise', trigger: 'select' }
    ]
  }

  private addGiftCardRate() {
    (this.$refs.rateFormAdd as Form).validate(async(valid: boolean) => {
      this.createRate = true

      if (valid) {
        try {
          await this.giftCardRate.save({ with: ['caretaker.id', 'giftCard.id'] })

          this.$notify({
            title: 'Taux de commission créée',
            message: 'Le Taux de commission à bien été créée',
            type: 'success',
            duration: 2000
          })
          this.$emit('created', this.giftCardRate.dup())
          this.newGiftCardRate()
        } catch (err) {
          this.createRate = false
        }
      }
      this.createRate = false
    })
  }

  private newGiftCardRate(): void {
    this.search = ''
    ;(this.$refs.popover_add_giftcard_rate as any).doClose()
    ;(this.$refs.rateFormAdd as Form).resetFields()
    this.giftCardRate = new GiftCardRate({ caretaker: this.caretaker })
  }

  async querySearchAsync(queryString: string, cb: any) {
    const { data } = await GiftCard
      .where({ name: { prefix: queryString } })
      .page(1)
      .per(999)
      .all()

    cb(data)
  }

  handleSelect(giftCard: GiftCard): void {
    this.giftCardRate.giftCard = giftCard
  }
}
