




import { Component, Vue, Prop } from 'vue-property-decorator'
import { Caretaker } from '@/models'

@Component({
  name: 'Dotations',
  components: {
    Budget: () => import('./components/budget.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) caretaker!: Caretaker
}
