





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCardRate } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'GiftCardRatesCard'
})
export default class extends Vue {
  @Prop({ required: true }) private giftCardRate!: GiftCardRate

  giftCardRateSelected: GiftCardRate | null = null
  updateLoading = false

  selectGiftCardRate(giftCardRate: GiftCardRate | null) {
    this.giftCardRateSelected = giftCardRate
    this.$emit('giftCardRateSelected', giftCardRate)
  }

  private async updateGiftCardRate() {
    (this.$refs.rateForm as Form).validate(async(valid: any) => {
      if (valid) {
        this.updateLoading = true
        const data: any = this.giftCardRate

        await data.save()
        this.updateLoading = false
      }
    })
  }

  private rules = {
    rate: [
      { required: true, message: 'Le taux de remise est requis', trigger: 'blur' }
    ]
  }
}
