var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-card',{attrs:{"body-style":{padding: 0, 'background-color': '#1890FF'}}},[_c('el-row',{staticStyle:{"padding":"10px"},attrs:{"type":"flex","align":"middle","justify":"space-between"}},[_c('h3',{staticClass:"text-white"},[_vm._v("Caretakers")]),_c('el-row',{staticStyle:{"gap":"20px"},attrs:{"type":"flex","align":"middle"}},[_c('el-input',{attrs:{"placeholder":"Nom caretaker"},on:{"change":_vm.handleFilter},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleFilter.apply(null, arguments)}},model:{value:(_vm.listQuery.name),callback:function ($$v) {_vm.$set(_vm.listQuery, "name", $$v)},expression:"listQuery.name"}}),_c('el-button',{attrs:{"round":""},on:{"click":function($event){return _vm.$router.push({name: 'CaretakerCreate'})}}},[_vm._v(" Ajouter un caretaker ")]),_c('el-button',{staticClass:"mx-0",attrs:{"round":""},on:{"click":_vm.getList}},[_vm._v(" Rafraîchir le tableau ")])],1)],1)],1),_c('el-card',{staticClass:"mt-20"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],key:_vm.tableKey,staticClass:"w-100",attrs:{"data":_vm.list,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Nom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Annonceur associé"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.advertiser.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Budget"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.budget ? _vm.$n(row.budget.amount, 'currency') : 'Pas de budget'))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('table.actions'),"align":"center","width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{attrs:{"icon":"el-icon-edit","type":"primary","size":"mini"},on:{"click":function($event){return _vm.$router.push({name: 'CaretakerEdit', params: {id: row.id}})}}}),_c('el-popconfirm',{attrs:{"title":"Êtes-vous sûr de vouloir supprimer ?"},on:{"confirm":function($event){return _vm.handleDelete(row, $index)}}},[(row.status !== 'deleted')?_c('el-button',{attrs:{"slot":"reference","icon":"el-icon-delete","size":"mini","type":"danger"},slot:"reference"}):_vm._e()],1)]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }