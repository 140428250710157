







































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Caretaker, BankTransfer, Budget, BudgetMove } from '@/models'
import Pagination from '@/components/Pagination/index.vue'
import { Form } from 'element-ui/types/element-ui'

@Component({
  name: 'Budget',
  components: {
    Pagination,
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) caretaker!: Caretaker

  private dialogFormVisible = false
  private newBankTransfer = new BankTransfer({ manageable: this.caretaker })
  private createBankTransferLoading = false

  budget: Budget | null = null
  budgetLoading = false

  budgetMoves: BudgetMove[] = []
  totalBudgetMoves = 0
  budgetMovesLoading = false

  listQuery = {
    page: 1,
    limit: 10
  }

  created() {
    this.getBudget()
  }

  private async getBudget() {
    this.budgetLoading = true

    const { data } =
      await Budget
        .where({ caretakerId: this.caretaker.id })
        .first()

    this.budget = data
    this.budgetLoading = false

    this.getBudgetMoves()
  }

  private async getBudgetMoves() {
    if (!this.budget) return

    this.budgetMovesLoading = true

    const { data, meta } =
      await BudgetMove
        .where({ budgetId: this.budget.id })
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .order({ createdAt: 'desc' })
        .stats({ total: 'count' })
        .all()

    this.budgetMoves = data
    this.totalBudgetMoves = meta.stats.total.count
    this.budgetMovesLoading = false
  }

  private createBudget() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      this.createBankTransferLoading = true
      if (valid) {
        const data = this.newBankTransfer
        try {
          await data.save({ with: ['manageable.id'] })

          this.$notify({
            title: 'Budget Created',
            message: 'Budget successfully created',
            type: 'success',
            duration: 2000
          })

          this.getBudget()
        } catch (err) {
          this.createBankTransferLoading = false
        }
      }
      this.createBankTransferLoading = false
      this.dialogFormVisible = false
    })
  }

  private uploadCallback(file: any) {
    this.newBankTransfer.proofOfPayment = file.blob.signed_id
  }
}
