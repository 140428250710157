var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticStyle:{"gap":"10px"},attrs:{"type":"flex","align":"middle"}},[_c('h3',{staticClass:"my-0 text-primary"},[_vm._v("Clients du programme :")]),_c('el-select',{staticClass:"select-border-0",attrs:{"placeholder":"Programme"},on:{"change":_vm.getCustomers},model:{value:(_vm.listQuery.programId),callback:function ($$v) {_vm.$set(_vm.listQuery, "programId", $$v)},expression:"listQuery.programId"}},_vm._l((_vm.programs),function(program){return _c('el-option',{key:program.id,attrs:{"label":program.name,"value":program.id}})}),1),_c('el-input',{staticStyle:{"max-width":"250px"},attrs:{"type":"text","placeholder":"Prénom"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCustomers.apply(null, arguments)}},model:{value:(_vm.listQuery.firstname),callback:function ($$v) {_vm.$set(_vm.listQuery, "firstname", $$v)},expression:"listQuery.firstname"}}),_c('el-input',{staticStyle:{"max-width":"250px"},attrs:{"type":"text","placeholder":"Nom de famille"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCustomers.apply(null, arguments)}},model:{value:(_vm.listQuery.lastname),callback:function ($$v) {_vm.$set(_vm.listQuery, "lastname", $$v)},expression:"listQuery.lastname"}})],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.customersLoading),expression:"customersLoading"}],key:_vm.tableKey,staticClass:"mt-20",staticStyle:{"width":"100%"},attrs:{"data":_vm.customers,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Nom / Prénom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(((row.lastname) + " " + (row.firstname)))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Inscrit le","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm._f("moment")(row.createdAt,'L à LT'),"placement":"top-start"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.createdAt,'L'))+" "),_c('i',{staticClass:"el-icon-info info-icon"})])])]}}])}),(!_vm.listQuery.programId)?_c('el-table-column',{attrs:{"label":"Programme affilié","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.program.name))])]}}],null,false,4022989305)}):_vm._e(),_c('el-table-column',{attrs:{"width":"65px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-popover',{ref:("popover_" + $index),attrs:{"placement":"right","width":"400","trigger":"click"}},[_c('el-row',{staticStyle:{"flex-direction":"column","padding":"10px 10px 0"},attrs:{"type":"flex"}},[_c('el-form',{ref:("programForm" + $index),attrs:{"rules":_vm.rules,"model":_vm.tempCustomer}},[_c('h4',{staticClass:"mt-0 text-primary"},[_vm._v("Changement de programme")]),_c('h6',{staticClass:"label-input"},[_vm._v("Nouveau programme du client")]),_c('el-row',{staticStyle:{"gap":"20px"},attrs:{"type":"flex","align":"top","justify":"space-between"}},[_c('el-form-item',{staticClass:"w-100",attrs:{"prop":"program.id"}},[_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Choisissez le nouveau programme"},model:{value:(_vm.tempCustomer.program.id),callback:function ($$v) {_vm.$set(_vm.tempCustomer.program, "id", $$v)},expression:"tempCustomer.program.id"}},_vm._l((_vm.programs),function(program){return _c('el-option',{key:program.id,attrs:{"label":program.name,"value":program.id}})}),1)],1),_c('el-button',{staticClass:"btn-success",attrs:{"type":"success","icon":"el-icon-link","loading":_vm.updateProgram},on:{"click":function($event){return _vm.updateCustomerProgram($index)}}})],1)],1)],1),_c('el-button',{staticClass:"btn-update",attrs:{"slot":"reference","type":"primary","size":"mini","icon":"el-icon-sort"},on:{"click":function($event){return _vm.setTempCustomer(row)}},slot:"reference"})],1)]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalCustomers > 0),expression:"totalCustomers > 0"}],attrs:{"total":_vm.totalCustomers,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getCustomers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }