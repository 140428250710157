


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Caretaker, Customer, Program } from '@/models/index'
import Pagination from '@/components/Pagination/index.vue'
import { Form, Popover } from 'element-ui'

@Component({
  name: 'CustomersByProgram',
  components: {
    Pagination
  }
})
export default class extends Vue {
  @Prop({ required: true }) private caretaker!: Caretaker

  private tableKey = 0
  private customers: Customer[] = []
  private totalCustomers = 0
  private tempCustomer: Customer = new Customer({ program: new Program({ id: '' }) })
  private customersLoading = false
  private programs: Program[] = []
  private programsLoading = true
  private updateProgram = false
  private listQuery = {
    page: 1,
    limit: 20,
    programId: '',
    firstname: '',
    lastname: ''
  }

  private rules = {
    'program.id': [{ required: true, message: 'Un programme est requis', trigger: 'blur' }]
  }

  created() {
    this.getPrograms()
  }

  private async getCustomers() {
    this.customersLoading = true
    const { data, meta } = await Customer
      .where({
        programId: this.listQuery.programId,
        firstname: { prefix: this.listQuery.firstname },
        lastname: { prefix: this.listQuery.lastname }
      })
      .includes('program')
      .page(this.listQuery.page)
      .per(this.listQuery.limit)
      .stats({ total: 'count' })
      .all()

    this.customers = data
    this.totalCustomers = meta.stats.total.count
    this.customersLoading = false
  }

  private async getPrograms() {
    this.programsLoading = true
    const { data } = await Program
      .where({ caretakerId: this.caretaker.id })
      .page(1)
      .per(999)
      .stats({ total: 'count' })
      .all()

    this.programs = data
    this.listQuery.programId = this.programs[0]?.id
    this.getCustomers()
    this.programsLoading = false
  }

  setTempCustomer(customer: Customer) {
    this.tempCustomer = customer.dup()
    if (!this.tempCustomer?.program?.id) this.tempCustomer.program = this.programs[0].dup()
  }

  private updateCustomerProgram(formIndex: number) {
    (this.$refs[`programForm${formIndex}`] as Form).validate(async(valid) => {
      this.updateProgram = true
      if (valid) {
        const data = this.tempCustomer

        try {
          await data.save({ with: 'program.id' })

          if (this.listQuery.programId) {
            this.customers.splice(formIndex, 1)
            this.totalCustomers--
          }
          this.$notify({
            title: 'Customer program updated',
            message: `${this.tempCustomer.firstname} ${this.tempCustomer.lastname} is on the program : ${this.tempCustomer.program.name}`,
            type: 'success',
            duration: 2000
          })
        } catch (err) {
          this.updateProgram = false
        }
      }
      this.updateProgram = false
      this.tableKey++
      (this.$refs[`popover_${formIndex}`] as Popover).$destroy()
    })
  }
}
