








































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import Pagination from '@/components/Pagination/index.vue'
import DirectUpload from '@/components/DirectUpload/index.vue'

import { Buyer, Location } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'ComplexTable',
  components: {
    Pagination,
    DirectUpload
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: Buyer[] = []
  private total = 0
  private listLoading = true
  private submitLoading = false
  private listQuery = {
    page: 1,
    limit: 20,
    name: undefined
  }

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private tempData = new Buyer({ location: new Location() })

  created() {
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
      await Buyer
        .includes('location')
        .where({ name: { prefix: this.listQuery.name } })
        .order({ name: 'asc' })
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .selectExtra(['logoBlob'])
        .stats({ total: 'count' }).all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private resetTempData() {
    this.tempData = new Buyer({ location: new Location() })
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleUpdate(row: any) {
    this.tempData = row
    if (!this.tempData.location) this.tempData.location = new Location()
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        this.submitLoading = true
        const data = this.tempData

        await data.save({ with: 'location' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.dialogStatus === 'create') {
            this.$notify({
              title: 'Buyer created',
              message: 'Buyer successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Buyer updated',
              message: 'Buyer successfully updated',
              type: 'success',
              duration: 2000
            })
          }
          this.submitLoading = false
          this.dialogFormVisible = false
        }
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Buyer> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private async handleDelete(data: Buyer, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }

  private uploadCallback(file: any) {
    this.tempData.logo = file.blob.signed_id
  }
}
