

























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Caretaker } from '@/models'
import { Form } from 'element-ui'
import { Program, GiftCard } from '@/models/index'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'ProgramList',
  components: {
    Pagination,
    DirectUpload: () => import('@/components/DirectUpload/index.vue'),
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) caretaker!: Caretaker

  programs: Program[] = []
  programsLoading = true
  totalPrograms = 0

  dialogFormVisible = false
  dialogStatus = ''
  textMap = {
    update: 'Mettre à jour le programme',
    create: 'Créer un programme'
  }

  listQuery = {
    page: 1,
    limit: 10
  }

  tempData: Program = new Program({ caretaker: this.caretaker })

  createOrUpdateLoading = false

  initialGiftCardsState: GiftCard[] = []

  created() {
    this.getPrograms()
  }

  async getPrograms() {
    this.programsLoading = true
    this.programs = []
    this.totalPrograms = 0

    const { data, meta } =
        await Program
          .where({ caretakerId: this.caretaker.id })
          .page(1)
          .per(999)
          .stats({ total: 'count' })
          .selectExtra(['logoBlob'])
          .all()

    this.programs = data
    this.totalPrograms = meta.stats.total.count


    this.programsLoading = false
  }

  resetTempData() {
    this.tempData = new Program({ caretaker: this.caretaker })
  }

  uploadCallback(file: any) {
    this.tempData.logo = file.blob.signed_id
  }

  private uploadCallbackSplashScreenLogo(file: any) {
    this.tempData.splashScreenLogo = file.blob.signed_id
  }

  private uploadCallbackSplashScreenBg(file: any) {
    this.tempData.splashScreenBackground = file.blob.signed_id
  }

  handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  handleUpdate(program: Program) {
    this.tempData = program
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  async handleDelete(program: Program, index: number) {
    await program.destroy()

    this.programs.splice(index, 1)
    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
  }

  createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      this.createOrUpdateLoading = true
      if (valid) {
        const data = this.tempData

        try {
          await data.save({ with: 'caretaker.id' })

          if (this.dialogStatus === 'create') {
            this.programs.push(data)
            this.$notify({
              title: 'Program Created',
              message: 'Program successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', data)
            this.$notify({
              title: 'Program Updated',
              message: 'Program successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        } catch (err) {
          this.createOrUpdateLoading = false
        }
      }
      this.createOrUpdateLoading = false
      this.dialogFormVisible = false
    })
  }
}
