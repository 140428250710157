

























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { GiftCardRate, Caretaker } from '@/models'

import GiftCardRatesCard from './components/GiftCardRatesCard.vue'
import Pagination from '@/components/Pagination/index.vue'

import { Notification } from 'element-ui'

@Component({
  name: 'RatesManagement',
  components: {
    Pagination,
    GiftCardRatesCard,
    FiltersDrawer: () => import('./components/FiltersDrawer.vue'),
    AddRate: () => import('./components/add-rate/AddRate.vue'),
    RateAsideSettings: () => import('./components/RateAsideSettings.vue'),
    RatesManagementHeader: () => import('./components/RatesManagementHeader.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private caretaker!: Caretaker

  private giftCardRates: GiftCardRate[] = []
  private giftCardRatesSelected: GiftCardRate | null = null

  private totalgiftCardRate = 0
  private giftCardRatesLoading = true

  private listQuery = {
    giftCardId: '',
    page: 1,
    limit: 20
  }

  async created() {
    await this.getRates()
  }

  private async getRates() {
    const { data, meta } = await GiftCardRate
      .where({ caretakerId: this.caretaker.id, giftCardId: this.listQuery.giftCardId })
      .includes(['giftCard', { discounts: ['program'] }])
      .page(this.listQuery.page)
      .per(this.listQuery.limit)
      .order({ createdAt: 'desc' })
      .stats({ total: 'count' })
      .all()

    this.giftCardRates = data
    this.totalgiftCardRate = meta.stats.total.count
    this.giftCardRatesLoading = false
  }

  private setGiftCardRatesSelected(giftCardRate: GiftCardRate | null) {
    if (this.giftCardRatesSelected?.id && this.giftCardRatesSelected?.id !== giftCardRate?.id) {
      (this.$refs[`card_${this.giftCardRatesSelected.id}`] as any)[0].giftCardRateSelected = null
    }
    this.giftCardRatesSelected = giftCardRate
  }

  private addGiftCardRate(giftCardRate: GiftCardRate) {
    this.giftCardRates.unshift(giftCardRate)
  }

  private async deleteGiftCardRate(index: number) {
    if (this.giftCardRates[index].discounts.length > 0) {
      Notification.warning({
        title: 'Can\'t delete',
        message: 'You can\'t delete rate with discounts',
        type: 'warning',
        duration: 5000
      })

      return
    }

    await this.giftCardRates[index].destroy()

    this.giftCardRates.splice(index, 1)

    Notification.success({
      title: 'GiftCardRate deleted',
      message: 'GiftCardRate has been deleted',
      type: 'success',
      duration: 5000
    })
  }

  private closeRateSettings() {
    this.giftCardRatesSelected = null
  }

  private setFilters(filters: any) {
    this.listQuery = { ...filters }
    this.refresh()
  }

  private refresh() {
    if (this.giftCardRatesSelected?.id) {
      (this.$refs[`card_${this.giftCardRatesSelected.id}`] as any)[0].selectGiftCardDiscount(null)
      this.giftCardRatesSelected = null
    }
    this.giftCardRatesLoading = true
    this.getRates()
  }
}
