











































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Advertiser, Caretaker } from '@/models/index'
import { ITagView, TagsViewModule } from '@/store/modules/tags-view'

@Component({
  name: 'CaretakerEdit',
  components: {
    ProgramsList: () => import('./components/programs-list.vue'),
    Dotations: () => import('./components/dotations/index.vue'),
    RatesManagement: () => import('./components/rates-management/index.vue'),
    CustomersByProgram: () => import('./components/customers-by-program.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  private caretaker: Caretaker = new Caretaker({ advertiser: new Advertiser({ id: '' }) })

  private advertiserList: Advertiser[] = []

  private caretakerLoading = false
  private advertiserListLoading = false

  created() {
    if (!this.isCreate) this.getCaretaker()
    this.getAdvertiserList()
  }

  mounted() {
    this.updateTagView()
  }

  get isCreate() {
    return this.$route.name === 'CaretakerCreate'
  }

  get loading() {
    return this.caretakerLoading || this.advertiserListLoading
  }

  private async getCaretaker() {
    this.caretakerLoading = true
    const { data } = await Caretaker
      .includes('advertiser')
      .selectExtra(['logoBlob'])
      .find(this.$route.params.id)

    this.caretaker = data
    this.updateTagView(data)
    this.caretakerLoading = false
  }

  private async getAdvertiserList() {
    this.advertiserListLoading = true
    const { data } = await Advertiser.all()

    this.advertiserList = data
    if (this.isCreate) this.caretaker.advertiser = data[0].dup()
    this.advertiserListLoading = false
  }

  private uploadCallback(file: any) {
    this.caretaker.logo = file.blob.signed_id
  }

  updateTagView(caretaker?: Caretaker) {
    const matchTagView: any = TagsViewModule.visitedViews.filter(view => view.params?.id === this.$route.params.id)

    if (matchTagView.length === 1) {
      matchTagView[0].title = `Caretaker ${caretaker?.name || 'loading...'}`
      matchTagView[0].meta.title = `Caretaker ${caretaker?.name || 'loading...'}`
      TagsViewModule.updateVisitedView({ ...matchTagView[0] as ITagView })
    }
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        await this.caretaker.save({ with: 'advertiser.id' })

        if (this.isCreate) {
          this.$notify({
            title: 'Caretaker created',
            message: 'Caretaker successfully created',
            type: 'success',
            duration: 2000
          })
          this.$router.replace({
            name: 'CaretakerEdit',
            params: { id: this.caretaker.id }
          }).catch(err => err)
        } else {
          this.$notify({
            title: 'Caretaker updated',
            message: 'Caretaker successfully updated',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }
}
